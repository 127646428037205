Apt.fn.extend('maps', {}, {
	/**
	 * Load territory geometry data
	 */
	initTerritories: function() {
		var scope = this;

		if (! scope.territoryReady) {
			scope.territoryReady = true;

			scope.bindPoly('territories');
		}

		scope.initSubterritories();

		if (scope.map.getZoom() >= 9) {
			return;
		}

		scope.getGroup('subterritories');
	},

	/**
	 * Load subterritory geometry data
	 */
	initSubterritories: function() {
		var scope = this;

		if (! scope.subterritoryReady) {
			scope.subterritoryReady = true;

			scope.bindPoly('subterritories');
		}

		scope.loadListings();
	}
});