Apt.fn.extend('maps', {}, {
	/**
	 * Initialize reset button
	 */
	initReset: function() {
		var scope = this,
			pub = scope.$public;

		if (! scope.conf.reset) {
			return;
		}

		$.events.on('$mapReset', 'mousedown', function() {
			scope.resetCenter();
		}, {
			delegate: scope.$overlay,
			namespace: scope.uid
		});

		pub.ready(function() {
			scope.initial = {
				center: pub.getCenter(true),
				zoom: pub.getZoom(true)
			};

			setTimeout(function() {
				scope.bindReset();
			}, 400);
		});
	},

	/**
	 * Bind map move event
	 */
	bindReset: function() {
		var scope = this,
			pub = scope.$public;

		pub.debounce('move', function() {
			if (! scope.active || scope.resetting) {
				return;
			}

			if (! $.equals(pub.getCenter(true), scope.initial.center)) {
				scope.model.$set('reset', true);
			}
		});
	},

	/**
	 * Reset map center
	 */
	resetCenter: function() {
		var scope = this;

		scope.resetting = true;

		scope.model.$set('reset', false);

		scope.$public.center(scope.initial.center, {
			animate: true,
			bearing: 0,
			zoom: scope.initial.zoom
		});

		setTimeout(function() {
			scope.resetting = false;
		}, 2000);
	}
});