/* global maplibregl */

Apt.fn.extend('maps', {
	/**
	 * Create map tooltip
	 *
	 * @param {Object} options
	 * @returns {Object}
	 */
	showTooltip: function(options) {
		var scope = this,
			priv = scope.$private;

		if (
			priv.tooltip &&
			options.id &&
			priv.tooltip.id === options.id
		) {
			return;
		}

		var className = 'map-popup';

		if (options.class) {
			className += ' ' + options.class;
		}

		scope.hideTooltip();

		var popup = new maplibregl.Popup({
			className: className,
			closeButton: false,
			closeOnClick: false,
			maxWidth: 'none',
			offset: 4
		});

		if (options.id) {
			scope.map.setFeatureState({
				id: options.id,
				source: 'points'
			}, {
				open: true
			});
		}

		popup.setLngLat(options.center)
			.setDOMContent(options.content)
			.addTo(scope.map);

		options.popup = popup;

		priv.tooltip = options;

		if ($.screen.size() > 2 && window.innerHeight > 480) {
			var point = scope.map.project(popup.getLngLat()),
				height = popup.getElement().offsetHeight;

			if (point.y < height) {
				var shift = scope.map.getCanvasContainer().offsetHeight - point.y,
					offset = height + 82;

				if (shift < offset) {
					scope.map.panBy([0, offset - shift], {
						duration: 400
					});
				}
			}
		}

		return popup;
	},

	/**
	 * Clear active tooltips
	 */
	hideTooltip: function() {
		var priv = this.$private;

		if (
			! priv.active ||
			! priv.tooltip
		) {
			return;
		}

		$.exec(priv.tooltip.onHide);

		priv.tooltip.popup.remove();

		if (priv.tooltip.id) {
			priv.map.removeFeatureState({
				id: priv.tooltip.id,
				source: 'points'
			}, 'open');
		}

		priv.tooltip = null;
	}
});