Apt.fn.extend('maps', {
	/**
	 * Highlight selected pin
	 *
	 * @param {Number} [id]
	 */
	highlight: function(id) {
		var scope = this,
			priv = scope.$private,
			feature;

		clearInterval(scope.pendingHighlight);

		delete scope.pendingHighlight;

		if (! priv.active) {
			return;
		}

		if (id && scope.highlighted) {
			if (typeof scope.highlighted === 'object') {
				if (scope.highlighted.id === id) {
					return;
				}
			} else if (scope.highlighted === id) {
				return;
			}
		}

		if (! id && ! scope.highlighted) {
			return;
		}

		scope.highlighted = id;

		// Check existing features
		if (id) {
			feature = scope.getFeature(id);
		}

		if (feature || ! id) {
			priv.highlightFeature(feature);

			return;
		}

		// Check again when the map is ready
		scope.pendingHighlight = scope.ready(function() {
			feature = scope.getFeature(id);

			if (! feature) {
				scope.highlighted = null;
			}

			priv.highlightFeature(feature);
		}, true);
	}
}, {
	/**
	 * Show highlighted feature
	 *
	 * @param {Object} [feature]
	 */
	highlightFeature: function(feature) {
		var scope = this;

		if (scope.highlighted) {
			try {
				scope.map.removeFeatureState({
					id: scope.highlighted.id,
					source: 'points'
				}, 'highlight');
			} catch (e) {
				//
			}

			scope.highlighted = null;
		}

		try {
			scope.map.getSource('highlight')
				.setData({
					type: 'FeatureCollection',
					features: feature ?
						[feature] : []
				});

			if (feature) {
				scope.map.setFeatureState({
					id: feature.id,
					source: 'points'
				}, {
					highlight: true
				});

				scope.highlighted = feature;
			}
		} catch (e) {
			//
		}
	},

	/**
	 * Create highlight layer
	 */
	addHighlight: function() {
		var scope = this;

		if (scope.conf.single) {
			return;
		}

		scope.addSource('highlight', {
			type: 'geojson',
			data: scope.empty
		});

		scope.addLayer({
			id: 'highlight',
			type: 'circle',
			source: 'highlight',
			paint: {
				'circle-color': '#c77474',
				'circle-radius': 5,
				'circle-stroke-color': '#f2f9f5'
			}
		}, 'labels');
	}
});