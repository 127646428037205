Apt.fn.extend('maps', {
	/**
	 * Check map enlargement status
	 *
	 * @returns {Boolean}
	 */
	enlarged: function() {
		return this.$private.enlarged === true;
	},

	/**
	 * Maximize map
	 */
	maximize: function() {
		this.$private.maximize();
	},

	/**
	 * Minimize map
	 */
	minimize: function() {
		this.$private.minimize();
	}
}, {
	/**
	 * Initialize enlargement
	 */
	initResize: function() {
		var scope = this;

		scope.enlarged = false;

		$.events.on('$mapResize', 'mousedown', function() {
			scope.enlarged ?
				scope.minimize() :
				scope.maximize();
		}, {
			delegate: scope.$overlay,
			namespace: scope.uid
		});
	},

	/**
	 * Maximize map
	 */
	maximize: function() {
		var scope = this;

		if (scope.enlarged) {
			return;
		}

		scope.enlarged = true;

		scope.$placeholder = $('<div/>');

		scope.$parent.after(scope.$placeholder);

		scope.$parent.appendTo('$wrapper')
			.addClass('-is-enlarged');

		scope.model.$set('enlarged', true);

		LS.util.crop();

		requestAnimationFrame(function() {
			scope.map.resize();
		});

		$(window).on('keydown', function(e) {
			if (
				scope.enlarged &&
				e.keyCode === 27
			) {
				scope.minimize();
			}
		}, {
			namespace: scope.uid
		});

		if (scope.conf.preview) {
			scope.map.dragRotate.enable();
		}
	},

	/**
	 * Minimize map
	 */
	minimize: function() {
		var scope = this;

		if (! scope.enlarged) {
			return;
		}

		scope.enlarged = false;

		scope.$placeholder.replaceWith(
			scope.$parent.removeClass('-is-enlarged')
		);

		scope.model.$drop('enlarged');

		LS.util.uncrop();

		scope.$public.resize(function() {
			if (! scope.conf.preview) {
				return;
			}

			scope.resetPitch(true, false);
			scope.resetCenter();

			if (scope.conf.preview) {
				scope.map.dragRotate.disable();
			}
		});
	}
});