Apt.fn.extend('maps', {}, {
	/**
	 * Add scale control
	 */
	addScale: function() {
		var scope = this;

		if (scope.scaleControl) {
			return;
		}

		scope.scaleControl = new maplibregl.ScaleControl({
			maxWidth: 110,
			unit: 'imperial'
		});

		scope.map.addControl(scope.scaleControl);
	},

	/**
	 * Disable scale control
	 */
	removeScale: function() {
		var scope = this;

		if (scope.scaleControl) {
			scope.map.removeControl(scope.scaleControl);

			scope.scaleControl = null;
		}
	}
});