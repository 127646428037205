Apt.fn.extend('maps', {}, {
	/**
	 * Add instance layer
	 *
	 * @param {Object} config
	 * @param {String} [before]
	 */
	addLayer: function(config, before) {
		var scope = this;

		if (scope.map.getLayer(config.id)) {
			return;
		}

		scope.map.addLayer(config, before);

		scope.$private.instance.layers[config.id] = [config, before];
	},

	/**
	 * Remove instance layer
	 *
	 * @param {String} [id]
	 */
	removeLayer: function(id) {
		var scope = this,
			instance = scope.$private.instance;

		if (id) {
			if (instance.layers[id]) {
				scope.map.removeLayer(id);

				delete instance.layers[id];
			}

			return;
		}

		Object.keys(instance.layers).forEach(function(id) {
			scope.map.removeLayer(id);
		});

		instance.layers = [];
	}
});