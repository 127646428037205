Apt.fn.extend('maps', {
	/**
	 * Replace markers
	 *
	 * @param {Array} points
	 */
	setMarkers: function(points) {
		var priv = this.$private;

		priv.layers.listings = points;

		priv.map.getSource('points')
			.setData(priv.empty);

		priv.addMarkers(function() {
			priv.fitBounds(points);
		});
	}
}, {
	/**
	 * Register event handlers for pin actions
	 */
	initMarkers: function() {
		var scope = this;

		scope.addMarkerLayers();

		if (
			scope.conf.basic ||
			scope.conf.single
		) {
			return;
		}

		LS.util.idle([
			scope.bindMarkerHover,
			scope.bindMarkerClick
		], scope);
	},

	/**
	 * Display individual markers
	 */
	addMarkerLayers: function() {
		var scope = this;

		scope.addLayer({
			id: 'markers',
			type: 'circle',
			source: 'points',
			minzoom: scope.conf.showPoints ?
				0 : 9,
			filter: ['!has', 'point_count'],
			paint: {
				'circle-color': [
					'case',
					['==', ['to-string', ['get', 'status']], 'pending'], '#6a672a',
					'#2b6c4b'
				],
				'circle-opacity': scope.conf.single ? 1 : [
					'case',
					['boolean', ['feature-state', 'highlight'], false], 0,
					['==', ['to-string', ['get', 'status']], 'pending'], 0.5,
					1
				],
				'circle-radius': 5,
				'circle-stroke-color': '#f2f9f5'
			}
		});

		if (scope.conf.single) {
			return;
		}

		scope.working = true;

		var desktop = $.screen.size() > 3;

		scope.addMarkerImage(function() {
			scope.addLayer({
				id: 'labels',
				type: 'symbol',
				source: 'points',
				minzoom: scope.conf.showPoints ?
					0 : 9,
				filter: [
					'all',
					['!has', 'point_count']
				],
				layout: {
					'icon-allow-overlap': false,
					'icon-image': 'mark',
					'icon-padding': 8,
					'icon-text-fit': 'both',
					'text-field': '{price_short}{suffix}',
					'text-font': [
						'averta-semi'
					],
					'text-letter-spacing': -.014,
					'text-line-height': 1,
					'text-offset': [0, -0.9],
					'text-size': 15
				},
				paint: {
					'icon-opacity': desktop ? [
						'case',
						['boolean', ['feature-state', 'open'], false], 0,
						1
					] : 1,
					'text-color': scope.getPinColors(),
					'text-opacity': desktop ? [
						'case',
						['boolean', ['feature-state', 'open'], false], 0,
						1
					] : 1
				}
			});

			scope.addHighlight();

			scope.working = false;
		});
	},

	/**
	 * Add additional points to map
	 *
	 * @param {Function} [fn]
	 */
	addMarkers: function(fn) {
		var scope = this,
			pub = scope.$public,
			points = scope.layers.listings,
			data = {
				type: 'FeatureCollection',
				features: points
			};

		if (! scope.active) {
			return;
		}

		scope.working = true;

		if (points && points.length) {
			points.forEach(function(point) {
				var format = point.properties.format,
					suffix = '';

				if (format === 'lease') {
					if (point.properties.period) {
						suffix = '/' + point.properties.period;
					}
				} else if (format === 'auction') {
					suffix = ' min';
				}

				point.properties.id = point.id;
				point.properties.suffix = suffix;
				point.properties.status = point.properties.status || 'active';

				point.properties.price_short = // eslint-disable-line
					point.properties.price ?
						'$' + LS.util.shortFormat(point.properties.price) : '$—';

				point.properties.size_short = // eslint-disable-line
					point.properties.size ?
						LS.util.shortFormat(point.properties.size, 1) + ' ac' : '— ac';
			});
		}

		var onData = function(e) {
			if (! e.isSourceLoaded) {
				return;
			}

			scope.working = false;

			pub.unbind('map', 'sourcedata', onData);

			if (fn) {
				requestAnimationFrame(function() {
					fn();
				});
			}
		};

		pub.bind('map', 'sourcedata', onData);

		try {
			scope.map.getSource('points')
				.setData(data);
		} catch (e) {
			//
		}
	},

	/**
	 * Add marker image
	 *
	 * @param {Function} fn
	 */
	addMarkerImage: function(fn) {
		var map = this.map;

		map.loadImage('/assets/modules/maps@' + $.get('versions.maps') + '/img/mark.png')
			.then(function(image) {
				if (image && ! map.hasImage('mark')) {
					map.addImage('mark', image.data, {
						stretchX: [
							[28, 45],
							[59, 76]
						],
						content: [14, 13, 89, 44],
						pixelRatio: 2
					});
				}

				fn();
			});
	},

	/**
	 * Bind pin hover events
	 */
	bindMarkerHover: function() {
		var scope = this,
			pub = scope.$public;

		[
			'highlight',
			'labels',
			'markers'
		].forEach(function(layer) {
			pub.bind(layer, 'mousemove', function(e) {
				var feature = e.features[0];

				if (scope.hoverMarker && scope.hoverMarker.id === feature.id) {
					return;
				}

				scope.deactivate();

				if (scope.hoverMarker) {
					scope.map.setFeatureState(scope.hoverMarker, {
						hover: false
					});
				}

				scope.hoverMarker = feature;

				scope.map.setFeatureState(scope.hoverMarker, {
					hover: true
				});

				if (e.features.length > 1 && scope.conf.onClusterEnter) {
					var features = scope.getUnique(e.features);

					if (features.length > 1) {
						scope.conf.onClusterEnter(pub, e.features);

						return;
					}
				}

				scope.model.$set('listing', feature.properties);
			});

			pub.bind(layer, 'mouseleave', function() {
				if (! scope.hoverMarker) {
					return;
				}

				scope.map.setFeatureState(scope.hoverMarker, {
					hover: false
				});

				scope.hoverMarker = null;

				scope.model.$drop('listing');
				scope.model.$drop('message');
			});
		});
	},

	/**
	 * Bind pin click events
	 */
	bindMarkerClick: function() {
		var scope = this,
			pub = scope.$public;

		pub.bind('map', 'click', function(e) {
			var features = scope.getUnique(
				scope.map.queryRenderedFeatures(e.point, {
					layers: [
						'highlight',
						'labels',
						'markers'
					],
					validate: false
				})
			);

			if (! features.length) {
				pub.hideTooltip();

				return;
			}

			if (scope.model) {
				scope.model.$drop('listing');
			}

			if (features.length > 1) {
				var zoom = scope.map.getZoom();

				if (zoom < 14.5) {
					pub.center(features[0].geometry.coordinates, {
						animate: true,
						zoom: Math.max(zoom + ((19.4 - zoom) * 0.25), zoom)
					});

					return;
				}
			}

			if (scope.conf.onClick) {
				scope.conf.onClick(pub, features);
			}
		});
	}
});