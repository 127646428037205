Apt.fn.extend('maps', {}, {
	/**
	 * Initialize zoom
	 */
	initZoom: function() {
		var scope = this;

		$.events.on({
			'$zoomIn': {
				mousedown: function() {
					scope.map.zoomIn(null, {
						originalEvent: {}
					});
				}
			},
			'$zoomOut': {
				mousedown: function() {
					scope.map.zoomOut(null, {
						originalEvent: {}
					});
				}
			}
		}, {
			delegate: scope.$overlay,
			namespace: scope.uid
		});

		scope.toggleZoom();
	},

	/**
	 * Bind map zoom listener
	 */
	watchZoom: function() {
		var scope = this;

		if (scope.conf.single) {
			return;
		}

		scope.$public.debounce('zoom', function(e) {
			var zoom = scope.map.getZoom();

			if (zoom >= 5) {
				var data = {
					listing: null
				};

				if (zoom >= 9) {
					data.location = null;
					data.status = null;
					data.subterritory = null;
					data.detailed = true;
				}

				scope.initTerritories();

				if (scope.model) {
					if (zoom < 9) {
						data.detailed = false;

						if (scope.toggle) {
							scope.toggle.disable();
						}
					}

					scope.model.$merge(data);
				}
			} else {
				var event = e.originalEvent;

				if (event && event.offsetX) {
					scope.hoverPoly('territories', [
						event.offsetX, event.offsetY
					]);
				}

				if (scope.model) {
					scope.model.$drop('message');
				}
			}

			if (zoom < 9) {
				scope.$public.hideTooltip();
			}

			if (scope.map.getLayer('group')) {
				scope.map.moveLayer(
					'group', zoom >= 6.2 ?
						'groupLabels' : 'parcels'
				);

				if (zoom < 6.2) {
					scope.dropGroupState();
				}
			}

			scope.toggleZoom();
		});
	},

	/**
	 * Toggle zoom message display
	 */
	toggleZoom: function() {
		var scope = this;

		if (! scope.model || scope.conf.showPoints) {
			return;
		}

		if (scope.map.getZoom() < 9) {
			scope.model.$merge({
				message: 'Zoom or click for listings',
				visible: null
			});

			return;
		}

		scope.model.$drop('message');
	}
});