Apt.fn.extend('maps', {}, {
	/**
	 * Add instance source
	 *
	 * @param {String} id
	 * @param {Object} config
	 */
	addSource: function(id, config) {
		var scope = this,
			instance = scope.$private.instance;

		if (instance.sources[id]) {
			return;
		}

		scope.map.addSource(id, config);

		instance.sources[id] = config;
	},

	/**
	 * Remove instance source
	 *
	 * @param {String} [id]
	 */
	removeSource: function(id) {
		var scope = this,
			instance = scope.$private.instance;

		if (id) {
			if (instance.sources[id]) {
				scope.map.removeSource(id);

				delete instance.sources[id];
			}

			return;
		}

		Object.keys(instance.sources).forEach(function(id) {
			scope.map.removeSource(id);
		});

		instance.sources = [];
	}
});