Apt.fn.extend('maps', {
	/**
	 * Pause positioning
	 */
	pausePosition: function() {
		this.$private.static = true;
	},

	/**
	 * Resume positioning
	 */
	resumePosition: function() {
		var scope = this;

		setTimeout(function() {
			scope.ready(function() {
				scope.$private.static = false;
			});
		}, 200);
	},

	/**
	 * Parse variables from position
	 *
	 * @returns {Object}
	 */
	parsePosition: function() {
		var center = LS.filters.get('center');

		if (center && center.length === 2) {
			var zoom = LS.filters.get('zoom');

			return {
				center: [
					Number(center[0]),
					Number(center[1])
				],
				zoom: zoom ?
					Number(zoom) : null
			};
		}

		return null;
	},

	/**
	 * Get parsed or map position
	 *
	 * @returns {Object}
	 */
	getPosition: function() {
		var scope = this,
			priv = scope.$private,
			position;

		if (! priv.active) {
			return null;
		}

		if (! priv.static) {
			position = scope.parsePosition();

			if (position) {
				return position;
			}
		}

		return {
			center: scope.getCenter(true),
			zoom: scope.getZoom(true)
		};
	}
}, {
	/**
	 * Update position to current values
	 */
	updatePosition: function() {
		var scope = this,
			pub = scope.$public;

		if (! scope.conf.position || scope.static) {
			return;
		}

		LS.filters.merge({
			center: pub.getCenter(true),
			zoom: pub.getZoom(true)
		});

		$.history.replace(
			LS.filters.build(LS.filters.root(), LS.filters.get())
		);
	}
});