Apt.fn.extend('maps', {
	/**
	 * Bind event listener
	 *
	 * @param {String} layer
	 * @param {String} event
	 * @param {Function} fn
	 * @param {Boolean} [once=false]
	 */
	bind: function(layer, event, fn, once) {
		var scope = this,
			instance = scope.$private.instance,
			action = once ?
				'once' : 'on';

		if (! instance) {
			return;
		}

		instance.events[layer] = instance.events[layer] || [];
		instance.events[layer][event] = instance.events[layer][event] || [];
		instance.events[layer][event].push(fn);

		layer === 'map' ?
			scope.map[action](event, fn) :
			scope.map[action](event, layer, fn);
	},

	/**
	 * Remove event listener
	 *
	 * @param {String} [layer]
	 * @param {String} [event]
	 * @param {Function} [fn]
	 */
	unbind: function(layer, event, fn) {
		var scope = this,
			instance = scope.$private.instance,
			events = instance.events;

		if (! layer) {
			for (layer in events) {
				for (event in events[layer]) {
					events[layer][event].forEach(function(fn) {
						scope.unbind(layer, event, fn);
					});
				}
			}

			instance.events = [];

			return;
		}

		events = events[layer];

		if (! events || ! events[event]) {
			return;
		}

		layer === 'map' ?
			scope.map.off(event, fn) :
			scope.map.off(event, layer, fn);

		instance.events[layer][event] = events[event].filter(function(e) {
			return e !== fn;
		});
	},

	/**
	 * Debounce event
	 *
	 * @param {String} event
	 * @param {Function} fn
	 * @param {Boolean} [once=false]
	 * @returns {String}
	 */
	debounce: function(event, fn, once) {
		var scope = this,
			priv = scope.$private,
			uid = LS.util.uid(),
			timer = event + 'ed' + uid,
			status = event + 'ing' + uid,
			start = function() {
				clearTimeout(priv[timer]);

				priv[status] = true;
			},
			end = function(e) {
				priv[timer] = setTimeout(function() {
					if (! priv[status] || ! priv.map) {
						return;
					}

					delete priv[status];

					try {
						fn(e);
					} catch (e) {
						//
					}

					if (once) {
						scope.unbounce(uid);
					}
				}, 100);
			};

		if (! priv.active) {
			return null;
		}

		scope.map.on(event + 'start', start);
		scope.map.on(event + 'end', end);

		priv.instance.bounced[uid] = [
			event, start, end
		];

		return uid;
	},

	/**
	 * Remove debounce events
	 *
	 * @param {String} [uid]
	 */
	unbounce: function(uid) {
		var scope = this,
			priv = scope.$private,
			instance = priv.instance;

		if (! uid) {
			for (uid in instance.bounced) {
				scope.unbounce(uid);
			}

			return;
		}

		var config = instance.bounced[uid],
			event = config[0];

		clearTimeout(priv[event + 'ed' + uid]);

		scope.map.off(event + 'start', config[1]);
		scope.map.off(event + 'end', config[2]);

		delete instance.bounced[uid];
	}
});