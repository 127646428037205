Apt.fn.extend('maps', {
	/**
	 * Get map style
	 */
	getStyle: function() {
		return LS.store.get('style') || 'dynamic';
	},

	/**
	 * Set map style
	 *
	 * @param {String} [style]
	 * @param {Boolean} [store=false]
	 * @param {Function} [fn]
	 */
	setStyle: function(style, store, fn) {
		var scope = this,
			priv = scope.$private,
			map = priv.map;

		priv.model.$set('style', style);

		if (store) {
			LS.store.set('style', style);
		}

		if (priv.conf.style === style) {
			return;
		}

		priv.conf.style = style;

		scope.bind('map', 'style.load', function() {
			scope.ready(function() {
				Object.keys(priv.instance.sources).forEach(function(id) {
					if (! priv.map.getSource(id)) {
						map.addSource(id, priv.instance.sources[id]);
					}
				});

				if (priv.model.$get('terrain')) {
					priv.setPitch(true);
				}

				priv.addMarkerImage(function() {
					Object.keys(priv.instance.layers).forEach(function(id) {
						if (! priv.map.getLayer(id)) {
							var layer = priv.instance.layers[id];

							map.addLayer(layer[0], layer[1]);
						}
					});

					priv.setTheme();

					priv.addMarkers();

					priv.updateGroupDensity();
				});

				var highlighted = scope.highlighted;

				if (highlighted) {
					scope.highlighted = null;

					scope.highlight(highlighted);
				}

				if (fn) {
					fn();
				}
			});
		}, true);

		scope.ready(function() {
			map.setStyle(priv.getStyling(style), {
				diff: false // Workaround for issue reapplying previously loaded style
			});
		});
	}
}, {
	/**
	 * Initialize style layers
	 */
	initStyle: function() {
		var scope = this,
			pub = scope.$public;

		scope.model.$set('layers', {
			active: [],
			bases: scope.bases,
			overlays: scope.overlays
		});

		pub.ready(function() {
			scope.setTheme();
		});

		$.events.on({
			'$mapLayer': {
				change: function(e, el) {
					scope.toggleLayer(el, el.checked);
				}
			},
			'$mapStyle': {
				change: function(e, el) {
					scope.removeOptions();

					pub.setStyle(el.value, true);

					LS.analytics.interact('map', 'style', el.value);
				}
			}
		}, {
			delegate: scope.$overlay,
			namespace: scope.uid
		});
	},

	/**
	 * Toggle layer
	 *
	 * @param {$} el
	 * @param {Boolean} [enable=false]
	 */
	toggleLayer: function(el, enable) {
		var scope = this,
			key = el.value,
			type = el.name;

		if (enable) {
			if (type === 'base') {
				if (scope.base) {
					scope.toggleLayer(scope.base);
				}

				scope.base = el;
			}

			scope.addTiles(type, key);

			LS.analytics.interact('map', 'layer', key);

			return;
		}

		if (type === 'base' && scope.base === el) {
			scope.base = null;
		}

		scope.model.$drop('layers.active', key);

		scope.removeLayer(key);
		scope.removeSource(key);
	},

	/**
	 * Get active style data
	 *
	 * @param {String} [style='dynamic']
	 * @returns {String}
	 */
	getStyling: function(style) {
		return '/assets/modules/maps@' + $.get('versions.maps') + '/data/' + (style || 'dynamic') + '.json';
	},

	/**
	 * Set map theme
	 */
	setTheme: function() {
		var scope = this,
			map = scope.map,
			style = scope.conf.style,
			single = scope.conf.single,
			options = {
				validate: false
			};

		if (! map) {
			return;
		}

		var pinColor = single ?
			'#c77474' : scope.getPinColors();

		map.setPaintProperty('markers', 'circle-color', pinColor, options);

		var strokes = [
			'markers'
		];

		if (! single) {
			strokes.push('highlight');
		}

		strokes.forEach(function(id) {
			var highlight = ['boolean', ['feature-state', 'highlight'], false],
				pending = ['==', ['to-string', ['get', 'status']], 'pending'],
				opacity = [
					'case',
					highlight, 0,
					pending, 0.45,
					0.6
				];

			map.setPaintProperty(id, 'circle-stroke-opacity', single ? 0.8 : [
				'interpolate', ['linear'], ['zoom'],
				13, opacity,
				13.1, style === 'terrain' ? opacity : [
					'case',
					highlight, 0,
					pending, 0.6,
					0.8
				]
			], options);

			map.setPaintProperty(id, 'circle-stroke-width', single ? 6 : [
				'case',
				['<=', ['to-number', ['get', 'size']], 3], 1.5,
				['log2', ['to-number', ['get', 'size']]]
			], options);
		});
	},

	/**
	 * Get pin colors
	 *
	 * @returns {Array}
	 */
	getPinColors: function() {
		return [
			'case',
			['boolean', ['feature-state', 'open'], false], '#c77474',
			['boolean', ['feature-state', 'highlight'], false], '#c77474',
			['boolean', ['feature-state', 'hover'], false], '#24543c',
			['==', ['to-string', ['get', 'status']], 'pending'], '#6a672a',
			['==', ['to-string', ['get', 'format']], 'auction'], '#633380',
			['==', ['to-string', ['get', 'format']], 'lease'], '#336e80',
			'#2b6c4b'
		];
	},

	/**
	 * Add tiles
	 *
	 * @param {String} type
	 * @param {String} key
	 */
	addTiles: function(type, key) {
		var scope = this,
			options = scope[type + 's'][key];

		scope.model.$push('layers.active', key);

		if (scope.map.getLayer(key)) {
			return;
		}

		scope.$public.ready(function() {
			var source = options.source;

			if (options.tiles) {
				source = {
					type: 'raster',
					tileSize: 512,
					tiles: [
						options.tiles
					]
				};
			}

			if (options.maxzoom) {
				source.maxzoom = options.maxzoom;
			}

			if (options.minzoom) {
				source.minzoom = options.minzoom;
			}

			scope.addSource(key, source);

			var layer = options.layer || {
				id: key,
				type: 'raster',
				source: key
			};

			if (options.opacity) {
				layer.paint = {
					'raster-opacity': options.opacity
				};
			}

			 scope.addLayer(layer, options.before || 'contours');
		});
	},

	/**
	 * Reset visible layers
	 */
	resetTiles: function() {
		var scope = this,
			map = scope.map;

		scope.$public.ready(function() {
			map.getStyle().layers.forEach(function(layer) {
				map.setLayoutProperty(layer.id, 'visibility', 'visible');
			});
		});
	}
});