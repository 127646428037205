Apt.fn.extend('maps', {}, {
	/**
	 * Initialize label toggle
	 */
	initLabel: function() {
		var scope = this;

		$.events.on('$mapLabel', 'change', function(e, el) {
			scope.map.setLayoutProperty('labels', 'text-field', el.checked ?
				'{size_short}' : '{price_short}'
			);

			if (scope.model) {
				scope.model.$merge({
					show: false,
					size: el.checked
				});
			}

			scope.removeOptions();

			if (el.checked) {
				LS.analytics.interact('map', 'label');
			}
		}, {
			delegate: scope.$overlay,
			namespace: scope.uid
		});
	}
});